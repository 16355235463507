import React, { useState, useCallback } from "react";
import "./Photos.css";
import Gallery from "react-photo-gallery";
import FadeIn from "react-fade-in";
import Carousel, { Modal, ModalGateway } from "react-images";

export default function About() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <FadeIn>
      <div className="homeContainer1">
        <h1 className="galleryTitle1">My latest men's collection 23/24</h1>
        <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <p className="galleryBody1">See full collection <a href="https://likkiweb.s3.eu-north-1.amazonaws.com/DOGGIE_COLLECTION.pdf"
      target="_blank" rel="noreferrer">PDF here</a></p>
      </div>
    </FadeIn>
  );
}


const photos = [
  {
    src: "https://likkiweb.s3.amazonaws.com/d1.png",
    width: 2.83,
    height: 2
  },
  {
    src: "https://likkiweb.s3.amazonaws.com/d2.png",
    width: 2.83,
    height: 2
  },
  {
    src: "https://likkiweb.s3.amazonaws.com/d3.png",
    width: 2.83,
    height: 2
  },
  {
    src: "https://likkiweb.s3.amazonaws.com/d4.png",
    width: 2.83,
    height: 2
  },
  {
    src: "https://likkiweb.s3.amazonaws.com/d5.png",
    width: 2.83,
    height: 2
  },
  {
    src: "https://likkiweb.s3.amazonaws.com/d6.png",
    width: 2.83,
    height: 2
  },
  {
    src: "https://likkiweb.s3.amazonaws.com/d7.png",
    width: 2.83,
    height: 2
  },
  {
    src: "https://likkiweb.s3.amazonaws.com/d8.png",
    width: 2.83,
    height: 2
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/collectionSHEETbw.png",
    width: 2.83,
    height: 2
  },
  {
    src: "https://likkiweb.s3.amazonaws.com/collectionSHEETcolour.png",
    width: 2.83,
    height: 2
  }
];