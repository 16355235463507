import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Header from './components/Header';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import Home from './pages';
import Portfolio from './pages/Portfolio';

  
function App() {
return (
    <div className="App">
    <Router>
    <Header/>
    <Navbar/>
    <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route path='/portfolio' element={<Portfolio/>} />
    </Routes>
    <Footer/>
    </Router>
    </div >
);
}
  
export default App;
