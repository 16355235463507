import React from 'react';
import './index.css';
import Carousel2 from '../components/Carousel2';
import Bg from './Bg';

const BodyText = () => {
  return (
    <div className="body-text">
      <Carousel2 />
      <p className="text-content">I am a fashion and clothing student from Helsinki,
       Finland. While fashion design is my primary focus, I'm eager to delve into related 
       fields like graphic design and creative direction. Here you can find my previous and 
       recent schoolwork and personal projects.</p>
      <Bg/>
    </div>
  );
};

export default BodyText;
