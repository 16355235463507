import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [blur, setBlur] = useState(false);

  useEffect(() => {
    const navbarLinks = document.querySelectorAll('.navbar li');
    navbarLinks.forEach((link) => {
      link.addEventListener('mouseover', () => setBlur(true));
      link.addEventListener('mouseout', () => setBlur(false));
    });
  }, []);

  return (
    <div class="parent">
    <nav className={`navbar ${blur ? 'blur' : ''}`}>
      <ul>
        <li>
          <NavLink to="/portfolio" activeClassName="active">Portfolio</NavLink>
        </li>
      </ul>
    </nav>
    </div>
  );
};

export default Navbar;
