import "./Carousel2.css";
import React, { useState, useEffect } from "react";

const Carousel2 = () => {
  const [photos] = useState([
    { src: "https://likkiweb.s3.amazonaws.com/KASVO1.png", alt: "eih" },
    { src: "https://likkiweb.s3.amazonaws.com/KASVO5.png", alt: "eih" },
    { src: "https://likkiweb.s3.amazonaws.com/KASVO2.png", alt: "eih" },
    { src: "https://likkiweb.s3.amazonaws.com/KASVO3.png", alt: "eih" },
    { src: "https://likkiweb.s3.amazonaws.com/KASVO4.png", alt: "eih" },
    { src: "https://likkiweb.s3.amazonaws.com/KASVO6.png", alt: "eih" },
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      const intervalId = setInterval(() => {
        setCurrentIndex((currentIndex + 1) % photos.length);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [currentIndex, loading, photos.length]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className="photo-carousel2">
      {photos.map((photo, index) => (
        <img
          key={index}
          src={photo.src}
          alt={photo.alt}
          onLoad={handleImageLoad}
          style={{ display: index === currentIndex ? "block" : "none" }}
        />
      ))}
    </div>
  );
};

export default Carousel2;
