import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <ul>
        <li>
          <a href="mailto:hinni.helkala@gmail.com">Email</a>
          &nbsp;/&nbsp;
          <a href="https://www.instagram.com/hinni____/" target="_blank" rel="noreferrer">Instagram</a>

        </li>
      </ul>
    </footer>
  );
};

const Container = () => {
  return (
    <div className="container">
      {/* Other components */}
      <Footer />
    </div>
  );
};

export default Container;
