import React, { useState, useCallback } from "react";
import "./Photos.css";
import Gallery from "react-photo-gallery";
import FadeIn from "react-fade-in";
import Carousel, { Modal, ModalGateway } from "react-images";

export default function About() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <FadeIn>
      <div className="homeContainer2">
        <h1 className="galleryTitle2">Drawings and sketches</h1>
        <Gallery photos={photos2} onClick={openLightbox} columns = '5' border-radius/>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos2.map(y => ({
                  ...y,
                  srcset: y.srcSet,
                  caption: y.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <p className="galleryBody2"></p>
      </div>
    </FadeIn>
  );
}

const photos2 = [
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/s4.JPG",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 2
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/s1.JPG",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 2
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/s2.JPG",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 2
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/s3.JPG",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 2
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/s5.JPG",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 2
  }

];
