import React, { useState, useCallback } from "react";
import "./Photos.css";
import Gallery from "react-photo-gallery";
import FadeIn from "react-fade-in";
import Carousel, { Modal, ModalGateway } from "react-images";

export default function About() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <FadeIn>
      <div className="homeContainer2">
        <h1 className="galleryTitle2">My creations</h1>
        <Gallery photos={photos2} onClick={openLightbox} columns = '5' border-radius/>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos2.map(y => ({
                  ...y,
                  srcset: y.srcSet,
                  caption: y.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <p className="galleryBody2">These pieces are mainly from my school projects with some being my spare time creations. Tops in
        images 1-4 and 9-13 are not my work. Creative direction by me, photography by Riki Karjalainen.</p>
      </div>
    </FadeIn>
  );
}

const photos2 = [
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l23.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l24.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l25.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l26.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l7.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l5.png",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2.686,
    height: 2
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l6.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l4.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 2.69
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l8.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l9.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l10.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l11.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l12.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l22.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l18.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l20.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l21.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l19.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l27.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 2
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l13.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l14.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l15.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 658,
    height: 911
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l16.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l17.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l1.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l2.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  },
  {
    src: "https://likkiweb.s3.eu-north-1.amazonaws.com/l3.jpg",
    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
    width: 2,
    height: 3.55
  }
];
