import Photos1 from './Photos1.js';
import Photos2 from './Photos2.js';
import Photos3 from './Photos3.js';
import "./Photos.css";

function Portfolio() {
  return (
    <div className="full">
      <Photos2/>
      <Photos1/>
      <Photos3/>
    </div>
);
}
  
export default Portfolio;
