import React from 'react';
import './index.css';

const Bg = () => {
  return (
    <div className="bg">
    </div>
  );
};

export default Bg;
